import React, { Component } from 'react'
import Auth from '../auth/auth'

import Layout from '../components/layout/layout'


let auth
if (typeof window !== `undefined`) {
  auth = new Auth()
}

const Callback = class extends Component {
  render() {
    if (typeof window !== `undefined`) {
      auth.handleAuthentication()
    }
    return (
      <Layout location={this.props.location.pathname} >
        <div className="section">
          <div className="container is-fluid">
            <div className="columns">
              <div className="column">
                <div className="level-item has-text-centered">
                  <div className="loader" style={{ fontSize: '5rem' }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Callback
